import React, {useContext, useEffect, useState} from 'react';
import NavBar from "../components/NavBar";
import RootLayout from "../components/RootLayout";
import {fetchTaskInfo} from "../http/courseAPI";
import {useParams} from "react-router-dom";
import AccessDeniedAlert from "../components/AccessDeniedAlert";

const Task = () => {
    const { moduleId, taskId } = useParams();
    const [instruction, setInstruction] = useState('');
    const [hasAccess, setHasAccess] = useState(true); // Состояние для отслеживания доступа

    function escapeInsideCodeBlocks(html) {
        return html.replace(/<code>(.*?)<\/code>/gs, (match, content) => {
            const escapedContent = content.replace(/</g, '&lt;').replace(/>/g, '&gt;');
            return `<code>${escapedContent}</code>`;
        });
    }
    
    

    useEffect(() => {
        fetchTaskInfo(moduleId, taskId)
            .then(data => {
                let processedContent = data.content;
            processedContent = escapeInsideCodeBlocks(processedContent); // Экранирование внутри <code>
            setInstruction(processedContent);
                setHasAccess(true); // Пользователь имеет доступ
            })
            .catch(error => {
                console.error(error);
                if (error.response && error.response.status === 403) {
                    setHasAccess(false); // Пользователь не имеет доступа
                }
            });
    }, [moduleId, taskId]);

    return (
        <RootLayout>
            <NavBar />
            {hasAccess ? (
                <div className="m-5 p-5 border border-primary rounded-3xl overflow-y-scroll h-[86.5vh] md:mx-0">
                    <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                </div>
            ) : (
                <AccessDeniedAlert /> // Отображение компонента, если доступ запрещен
            )}
        </RootLayout>
    );
};

export default Task;